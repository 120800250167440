<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="chartOfAccounts"
      dense
      hide-default-footer
      disable-pagination
      @click:row="detailChartOfAccount"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                flat
                dense
                outlined
                background-color="white"
                v-model="search"
                placeholder="search"
                append-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    dark
                    rounded
                    :to="{ name: 'Create Chart Of Account' }"
                    class="mr-2"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Create</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                    dark
                    rounded
                    :to="{ name: 'Print Chart Of Account', query: { size: pageSize } }"
                  >
                    <v-icon>
                      mdi-printer
                    </v-icon>
                  </v-btn>
                </template>
                <span>Print Preview</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" v-bind="attrs" v-on="on" dark rounded @click="exportExcel">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Export</span>
          </v-tooltip> -->
          <!-- <v-col cols="4" sm="4" md="2" lg="2" class="py-0" align-self="center">
            <v-select
              class="pt-6"
              outlined
              dense
              flat
              background-color="white"
              :items="listSize"
              v-model="pageSize"
              @change="onChangeSize"
            ></v-select>
          </v-col>
          <v-spacer></v-spacer> -->
        </v-toolbar>
      </template>
      <template v-slot:item.no="{ item }">
        {{ index(item) + 1 }}
      </template>
      <template v-slot:item.openingBalance="{ item }">
        {{ formatPrice(item.balance) }}
      </template>
      <template v-slot:item.transaction="{ item }">
        {{ item.transaction ? "YES" : "NO" }}
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    headers: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 2,
        text: "Account No",
        value: "accountNumber",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 3,
        text: "Name",
        value: "accountName",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 4,
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 5,
        text: "Pointer",
        value: "pointer",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 6,
        text: "Nature",
        value: "nature",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Balance",
        value: "openingBalance",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Transaction",
        value: "transaction",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 7,
        text: "Type",
        value: "type",
        sortable: false,
        divider: true,
        align: "start",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    listSize: [50, 100, 200, 500, { text: "All", value: 0 }],
    pageSize: 0,
    search: "",
  }),

  computed: {
    ...mapState("chartOfAccount", ["chartOfAccounts"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("chartOfAccount/getAll", { size: this.pageSize })
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    detailChartOfAccount(item) {
      this.$router.push({ name: "Detail Chart Of Account", params: { id: item.id } });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onChangeSize() {
      this.initialize();
    },
    index(item) {
      return this.chartOfAccounts.map(x => x).indexOf(item);
    },
    async exportExcel() {
      this.$store.commit("SET_LOADER", true);
      try {
        const response = await this.$store.dispatch("external/exportExcel");
        if (response.status === 200) {
          this.$store.commit("SET_LOADER", false);
        }
      } catch (error) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
        });
        this.$store.commit("SET_LOADER", false);
      }
    },
  },
};
</script>

<style></style>
